import React, { useState, useEffect } from 'react';
import './Header.css';
import Logo from '../../assets/imgs/Logo.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import useRoutingService from '../../services/routing/routing';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../services/user/user';
import PayPal from '../PayPal/PayPal';

const Header = () => {
  const user = useUser();
  const routing = useRoutingService();

  const [scrollClass, setScrollClass] = useState('navbar-small');

  const [isSlidingOut, setIsSlidingOut] = useState(false);
  const [isSlidingIn, setIsSlidingIn] = useState(false);
  const [timerOut, setTimerOut] = useState(null);
  const [timerIn, setTimerIn] = useState(null);

  const location = useLocation();
  const currentPath = location.pathname;

  const logout = () => {
    user.logout("Erfolgreich abgemeldet.").then(() => {
      routing.goTo("/");
    });
  }

  useEffect(() => {
    if(currentPath == "/"){
      setScrollClass("navbar-big");
    }else{
      setScrollClass("navbar-small");
    }
    
    const handleScroll = () => {
      if(currentPath == "/"){
        const scrollPosition = window.scrollY;  

        if (scrollPosition > 200) {
          setScrollClass('navbar-small');
        } else {
          setScrollClass('navbar-big');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup Event-Listener beim Unmounten des Komponents
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  const handleMouseEnter = () => {
    clearTimeout(timerOut);
    clearTimeout(timerIn);

    setTimerOut(null);
    setTimerIn(null);

    const timeOut = setTimeout(() => {
      setIsSlidingOut(true);
    
      const timeIn = setTimeout(() => {
        setIsSlidingIn(true);
      }, 1000);

      setTimerIn(timeIn);
    }, 5000);

    setTimerOut(timeOut); 
  };

  const handleMouseLeave = () => {
    clearTimeout(timerOut);
    clearTimeout(timerIn);
    setIsSlidingOut(false);
    setIsSlidingIn(false);
  };

  useEffect(() => {
    // Cleanup, um sicherzustellen, dass der Timeout gelöscht wird, wenn die Komponente unmontiert wird
    return () => {clearTimeout(timerOut);clearTimeout(timerIn);}
  }, [timerOut, timerIn]);

  return (
    <Navbar expand="lg" className={`bg-body-tertiary ${scrollClass}`}>
     <Container style={{gap:'40px'}}>
       <Navbar.Text style={{flex:'1', display:'flex', alignItems:'center'}}>
         <Nav>
            <Nav.Link onClick={() => {routing.goTo('/tasks')}}>Hausaufgabengenerator</Nav.Link>
            {user.isLoggedIn() && (
              <Nav.Link onClick={() => {routing.goTo('/pdf')}}>Arbeitsblatt erstellen</Nav.Link>
            )}            
         </Nav>
       </Navbar.Text>
       <Navbar.Text>
        {user.isLoggedIn() && (
          <div style={{display:'flex', gap:'30px'}}>
            <Nav.Link onClick={() => {routing.goTo('/user')}}>Benutzer</Nav.Link>
            <Nav.Link onClick={() => {logout()}}>Abmelden</Nav.Link>
          </div>
        )}
        {!user.isLoggedIn() && (
          <Nav.Link onClick={() => {user.openLoginModal()}}>Anmelden</Nav.Link>
        )}
       </Navbar.Text>
       <Navbar.Brand onClick={() => {routing.goTo('/')}} style={{cursor:'pointer', flex: '0 1 200px', padding:'0', height: '100%'}}>
        <div
          className={`${isSlidingOut ? 'img-slide-out' : ''} ${isSlidingIn ? 'img-slide-in' : ''} logo`}
          style={{
            backgroundImage: `url(${Logo})`,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        ></div>
       </Navbar.Brand>
     </Container>
   </Navbar>
  );
};

export default Header;