import React, { useState, useEffect } from 'react';
import './DownloadApp.css';
import { Card } from 'react-bootstrap';
import { BsAndroid, BsApple } from 'react-icons/bs';
import AppleDownload from '../../assets/imgs/dlApple.png'
import GoogleDownload from '../../assets/imgs/dlGoogle.png'
import SchoolAILogo from '../../assets/imgs/Logo.png'

const DownloadApp = () => {
  return (
    <Card>
      <div style={{width:"100%", display:"flex", justifyContent:"center", marginBottom:"20px"}}>
        <img src={SchoolAILogo} style={{maxWidth:"200px"}} />
      </div>
      <div class="dl-app-row">
        <div class="dl-app">
          <p style={{fontSize:"1.5rem", textAlign:"center"}}>Apple App Store</p>
          <BsApple size={90}/>
          <p style={{marginTop:"10px"}}>Jetzt herunterladen</p>
          <img src={AppleDownload} style={{maxWidth:"200px", width:"100%"}} />
        </div>
        <div class="dl-app">
          <p style={{fontSize:"1.5rem", textAlign:"center"}}>Google Play Store</p>
          <BsAndroid size={90}/>
          <p style={{marginTop:"10px"}}>Jetzt herunterladen</p>
          <img src={GoogleDownload} style={{maxWidth:"200px", width:"100%"}}  />
        </div>
      </div>
    </Card>
  );
};

export default DownloadApp;