import * as React from 'react';
import "./Quiz.css";
import { Row, Card, Col } from 'react-bootstrap';
import useRoutingService from '../../../services/routing/routing';


const Quiz = () => {
  const routing = useRoutingService();

  return (
    <div>
      <Row>
        <Col>
          <Card>
              <Card.Body>
                  <Card.Title>Quiz-Aufgaben</Card.Title>
                  <Card.Text>
                    Die Kategorie "Quiz" enthält spannende und kurze Fragen, mit denen Wissen spielerisch getestet werden kann. Ein Quiz besteht aus verschiedenen Aufgabenformaten wie Multiple-Choice-Fragen. Es ist eine unterhaltsame Möglichkeit, Gelerntes zu wiederholen und das Verständnis zu überprüfen.       
                 </Card.Text>
                  </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>   
        <Col className="showCard">
            <Card className='hovercard' onClick={() => {routing.goTo('/tasks/quiz/textquiz')}} style={{cursor:'pointer'}}>
                <Card.Body>
                    <Card.Title>Quiz zu einem Text</Card.Title>
                    <Card.Text>
                      Erstelle ein Multiple Choice Quiz zu einem bestimmten Text
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col className="showCard" style={{animationDelay:"0.2s"}}>
            <Card className='hovercard' onClick={() => {routing.goTo('/tasks/quiz/multiplechoice')}} style={{cursor:'pointer'}}>
                <Card.Body>
                    <Card.Title>Multiple Choice</Card.Title>
                    <Card.Text>
                        Erstelle ein Multiple Choice Quiz für ein bestimmtes Thema
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    </Row>
  </div>
  );
};

export default Quiz;