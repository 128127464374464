import * as React from 'react';
import "./Art.css";
import { Row, Card, Col } from 'react-bootstrap';
import useRoutingService from '../../../services/routing/routing';


const Art = () => {
  const routing = useRoutingService();

  return (
    <div>
      <Row>
        <Col>
          <Card>
              <Card.Body>
                  <Card.Title>Kunst-Aufgaben</Card.Title>
                  <Card.Text>
                    Die Kategorie "Kunst" enthält die Möglichkeit zufallsgenerierte Mandals erstellen zu lassen.
                 </Card.Text>
              </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>   
        <Col className="showCard">
            <Card className='hovercard' onClick={() => {routing.goTo('/tasks/art/mandala')}} style={{cursor:'pointer'}}>
                <Card.Body>
                    <Card.Title>Mandala</Card.Title>
                    <Card.Text>
                      Lass dir ein zufallsgeneriertes Mandala erzeugen.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    </Row>
  </div>
  );
};

export default Art;