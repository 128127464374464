import * as React from 'react';
import "./Deutsch.css";
import { Row, Card, Col } from 'react-bootstrap';
import useRoutingService from '../../../services/routing/routing';

const Deutsch = () => {
  const routing = useRoutingService();

  return (
    <div>
        <Row>
            <Col>
                <Card>
                    <Card.Body>
                        <Card.Title>Deutsch</Card.Title>
                        <Card.Text>
                            Du kannst zwischen Lückentexten und Lesebeispielen wählen.
                            <br/>
                            Unser Service unterstützt dabei, Sprach- und Lesefähigkeiten gezielt zu verbessern.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row>   
            <Col className="showCard">
                <Card className='hovercard' onClick={() => {routing.goTo('/tasks/deutsch/clozetext')}} style={{cursor:'pointer'}}>
                    <Card.Body>
                        <Card.Title>Lückentext</Card.Title>
                        <Card.Text>
                            Texte mit verschiedenen Darstellungen der Lücken
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="showCard" style={{animationDelay:"0.2s"}}>
                <Card className='hovercard' onClick={() => {routing.goTo('/tasks/deutsch/reading')}} style={{cursor:'pointer'}}>
                    <Card.Body>
                        <Card.Title>Lesebeispiele</Card.Title>
                        <Card.Text>
                            Texte zum Lesen - ob gespiegelt oder als Pyramide
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </div>
  );
};

export default Deutsch;