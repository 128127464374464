import React from 'react';
import { Form, Card } from 'react-bootstrap';
import "./Niveau.css";

const Niveau = ({ niveau, onChanged }) => {
    const handleNiveau = (n) => {
        onChanged(n.target.value);
    };

    return (
        <Card>
            <Card.Body>
                <Card.Title>Wähle ein Niveau aus</Card.Title>
                <Card.Text>
                    <Form.Select value={niveau} size="sm" onChange={handleNiveau}>
                        <option value="-1">Bitte Wählen</option>
                        <option value="1">Grundschulniveau Klasse 1</option>
                        <option value="2">Grundschulniveau Klasse 2</option>
                        <option value="3">Grundschulniveau Klasse 3</option>
                        <option value="4">Grundschulniveau Klasse 4</option>
                    </Form.Select>
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default Niveau;