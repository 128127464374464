import React, { useState } from 'react';
import { Button, Card, Form, FloatingLabel } from 'react-bootstrap';
import useApiService from '../../services/api/api';

const GeneratePDFButtonMandala = ({file, onGenerate, setTopic}) => {
  const api = useApiService();

  const [generating, setGenerating] = useState(false);
  const [description, setDescription] = useState("");
  const [images, setImages] = useState(false);
  const [theTopic, setTheTopic] = useState(setTopic);

  const handleGenerate = () => {
    setGenerating(true);

    api.createPDFMandala(file, theTopic, description, images).then(() => {
      setGenerating(false);
      onGenerate();
    });
  }

  const handleTopic = (e) => {
    setTheTopic(e.target.value);
  }

  const handleDescription = (e) => {
    setDescription(e.target.value);
  }

  const handleImages = (e) => {
    setImages(e.target.checked);
  }
  
  return (
    <Card>
      <Card.Body>
          <Card.Title>PDF Generierung</Card.Title>
          <Card.Text style={{marginTop: '20px'}}>
              <FloatingLabel controlId="floatingTopic" onChange={handleTopic}  label="Thema" className="mb-3">
                  <Form.Control type="text" placeholder="Thema" value={theTopic} />
              </FloatingLabel>
              <Form.Label style={{marginBottom: '3px'}}>Aufgabenbeschreibung</Form.Label>
              <Form.Control 
                  value={description} 
                  as="textarea" 
                  style={{ height: '200px', marginBottom: '20px' }}
                  onChange={handleDescription}  
              />
              <Form.Check // prettier-ignore
                type="switch"
                id="with-images"
                label="PDF mit Bildern generieren"
                onChange={handleImages}
                style={{ marginBottom: '30px' }}
              />
              <Button disabled={generating} variant="primary" onClick={handleGenerate}>Generieren</Button>
          </Card.Text>
          {images && (
            <Card.Text>
              Hinweis: Die Generierung mit Bildern dauert länger.
            </Card.Text>
          )}
      </Card.Body>
    </Card>
  );
};

export default GeneratePDFButtonMandala;