import * as React from 'react';
import "./Tasks.css";
import textImg from '../../assets/imgs/text.jpg';
import matheImg from '../../assets/imgs/mathe.jpg';
import quizImg from '../../assets/imgs/quiz.jpg';
import artImg from '../../assets/imgs/art.jpg';
import { Row, Col, Card } from 'react-bootstrap';
import useRoutingService from '../../services/routing/routing';

const Tasks = () => {
    const routing = useRoutingService();

    return (
        <div>
            <Row>   
                <Col className="showCard">
                    <Card className='hovercard' onClick={() => {routing.goTo('/tasks/deutsch')}} style={{cursor:'pointer'}}>
                        <Card.Img variant="top" src={textImg} />
                        <Card.Body>
                            <Card.Title>Deutsch</Card.Title>
                            <Card.Text>
                                Wähle das Fach Deutsch aus. Du kannst zwischen Lückentexten und Lesebeispielen wählen, die speziell für den Unterrichtsstoff zugeschnitten sind.
                                <br/>
                                Unser Service unterstützt dabei, Sprach- und Lesefähigkeiten gezielt zu verbessern.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="showCard" style={{animationDelay:"0.2s"}}>
                    <Card className='hovercard' onClick={() => {routing.goTo('/tasks/math')}} style={{cursor:'pointer'}}>
                        <Card.Img variant="top" src={matheImg} />
                        <Card.Body>
                            <Card.Title>Mathe</Card.Title>
                            <Card.Text>
                                Im Fach Mathe bieten wir Aufgaben zu den Grundrechenarten an. Perfekt, um die Fähigkeiten in Addition, Subtraktion, Multiplikation und Division zu üben und zu festigen!
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="showCard" style={{animationDelay:"0.4s"}}>
                    <Card className='hovercard' onClick={() => {routing.goTo('/tasks/quiz')}} style={{cursor:'pointer'}}>
                        <Card.Img variant="top" src={quizImg} />
                        <Card.Body>
                            <Card.Title>Quiz</Card.Title>
                            <Card.Text>
                                Die Quizze sind darauf ausgelegt, das Wissen in verschiedenen Fachbereichen zu testen und zu erweitern. Quizze können aus eigenen Themenbereichen oder Texten erzeugt werden.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="showCard" style={{animationDelay:"0.6s"}}>
                    <Card className='hovercard' onClick={() => {routing.goTo('/tasks/art')}} style={{cursor:'pointer'}}>
                        <Card.Img variant="top" src={artImg} />
                        <Card.Body>
                            <Card.Title>Kunst</Card.Title>
                            <Card.Text>
                                Im Grundschulfach Kunst entdecken Kinder kreativ Farben, Formen und Techniken. Beim Ausmalen von Mandalas schulen sie Feinmotorik, Konzentration und entspannen spielerisch.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Tasks;