import * as React from 'react';
import "./Mandala.css";
import { Row, Card, Col, Form, Button, ToggleButton } from 'react-bootstrap';
import { useState, useRef } from "react";
import { useUser } from '../../../../services/user/user';
import useApiService from '../../../../services/api/api';
import GeneratePDFButtonMandala from '../../../../components/GeneratePDFButton/GeneratePDFButtonMandala';

const Mandala = () => {
  const user = useUser();
  const [points, setPoints] = useState(1);

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);

  const api = useApiService();

  const canvasRef = useRef(null);

  const [width, setWidth] = useState(750);
  const [height, setHeight] = useState(750);

  const handlePointChanged = (p) => {
    setPoints(parseInt(p.target.value))
  }

  const createMandala = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    function draw() {
      ctx.fillStyle = "#fff";
      ctx.fillRect(0,0,canvas.width,canvas.height);
  
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
  
      const numLayers = getRandomInt(0 + points, 1 + points);
      const numCirclesPerLayerStart = getRandomInt(2 +points, 5 + points);
      
      const maxRadius = getRandomInt(175 - (points * 12.5), 225 - (points * 12.5));

      const allowedForms = [];

      if(!checked1){
        allowedForms.push(1);
      }

      if(!checked2){
        allowedForms.push(2);
      }

      if(!checked3){
        allowedForms.push(3);
      }

      if(!checked4){
        allowedForms.push(4);
      }
      
      for (let layer = 1; layer <= numLayers; layer++) {
        let stepRadius = maxRadius / layer;

        let form = allowedForms[getRandomInt(0, allowedForms.length - 1)];
  
        let numCirclesPerLayer = numCirclesPerLayerStart;
  
        for (let i = 0; i < numCirclesPerLayer; i++) {
          let angle = (i / numCirclesPerLayer) * Math.PI * 2;
          let x = centerX + Math.cos(angle) * stepRadius;
          let y = centerY + Math.sin(angle) * stepRadius;
  
          let size = stepRadius;
  
          ctx.beginPath();
  
          if(form == 3){
            let halfSize = size / 2;
  
            let cosA = Math.cos(angle + Math.PI / 2);
            let sinA = Math.sin(angle + Math.PI / 2);
            
            let x1 = x + cosA * halfSize;
            let y1 = y + sinA * halfSize;
            let x2 = x - sinA * halfSize;
            let y2 = y + cosA * halfSize;
            let x3 = x - cosA * halfSize;
            let y3 = y - sinA * halfSize;
            let x4 = x + sinA * halfSize;
            let y4 = y - cosA * halfSize;
  
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);
            ctx.lineTo(x3, y3);
            ctx.lineTo(x4, y4);
            ctx.closePath();
          }else if(form == 2){
            let angle1 = angle + Math.PI / 2;
            let angle2 = angle - Math.PI / 2;
            let tipX = x + Math.cos(angle) * size;
            let tipY = y + Math.sin(angle) * size;
            let baseX1 = x + Math.cos(angle1) * (size / 2);
            let baseY1 = y + Math.sin(angle1) * (size / 2);
            let baseX2 = x + Math.cos(angle2) * (size / 2);
            let baseY2 = y + Math.sin(angle2) * (size / 2);
  
            ctx.moveTo(tipX, tipY);
            ctx.lineTo(baseX1, baseY1);
            ctx.lineTo(baseX2, baseY2);
            ctx.closePath();
          }else if(form == 4){
            for (let j = 0; j <= 10; j++) {
              let starAngle = angle + (j * Math.PI / 5); // 36° Schritte für Sternform
              let r = j % 2 === 0 ? size : size / 2;
              let sx = x + Math.cos(starAngle) * r;
              let sy = y + Math.sin(starAngle) * r;
              if (j === 0) {
                ctx.moveTo(sx, sy);
              } else {
                ctx.lineTo(sx, sy);
              }
            }
          }else{
            ctx.arc(x, y, stepRadius / 2, 0, Math.PI * 2);
          }
          
          ctx.stroke();
        }
      }
    }

    function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    draw();
  }

  const handleGeneratePDFClick = (event) => {
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      if (!blob) return;
  
      // Blob in File umwandeln
      const file = new File([blob], "canvas-image.jpg", { type: "image/jpeg" });

      user.openModal((<GeneratePDFButtonMandala file={file} onGenerate={() => {
          user.closeModal()
      }} setTopic={"Mandala"} />));
    }, "image/jpeg", 0.8);
  }

  return (
    <div>
      <Row>
        <Col>
          <Card>
              <Card.Body>
                  <Card.Title>Mandala</Card.Title>
                  <Card.Text>
                    Ein Mandala ist ein kreisförmiges Muster mit symmetrischen Formen und wiederkehrenden Elementen. Es fördert Kreativität, Konzentration und Entspannung beim Ausmalen.
                 </Card.Text>
                 <Card.Text>
                    <Form.Label>Komplexität</Form.Label>
                    <Form.Range min="1" max="10" onChange={handlePointChanged} onClick={handlePointChanged} value={points} />
                  </Card.Text>
                  <Card.Text>
                    <Form.Label>Erlaubte Formen</Form.Label>
                    <br/>
                    <ToggleButton
                      id="toggle-check1"
                      type="checkbox"
                      variant="primary"
                      checked={checked1}
                      value="1"
                      onChange={(e) => setChecked1(e.currentTarget.checked)}
                      style={{marginRight:"10px"}}
                    >Kreise</ToggleButton>
                    <ToggleButton
                      id="toggle-check2"
                      type="checkbox"
                      variant="primary"
                      checked={checked2}
                      value="2"
                      onChange={(e) => setChecked2(e.currentTarget.checked)}
                      style={{marginRight:"10px"}}
                    >Dreiecke</ToggleButton>
                    <ToggleButton
                      id="toggle-check3"
                      type="checkbox"
                      variant="primary"
                      checked={checked3}
                      value="3"
                      onChange={(e) => setChecked3(e.currentTarget.checked)}
                      style={{marginRight:"10px"}}
                    >Vierecke</ToggleButton>
                    <ToggleButton
                      id="toggle-check4"
                      type="checkbox"
                      variant="primary"
                      checked={checked4}
                      value="4"
                      onChange={(e) => setChecked4(e.currentTarget.checked)}
                      style={{marginRight:"10px"}}
                    >Sterne</ToggleButton>
                  </Card.Text>
              </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
              <Card.Body>
                  <Card.Text>
                    <Button variant="primary" onClick={() => {createMandala()}}>Erzeugen</Button>
                    {user.isLoggedIn() && user.getUser().school.licence.active && (
                      <Button disabled={api.loading} variant="primary" onClick={handleGeneratePDFClick} style={{marginLeft: '10px'}}>PDF generieren</Button>
                    )}
                    {!user.isLoggedIn() && (
                        <span style={{marginLeft:"10px"}}>
                            <Button variant="primary" onClick={() => {user.openRegister()}} style={{marginRight:'10px'}}>Registrieren</Button>
                            <Button variant="primary" onClick={() => {user.openLoginModal()}}>Anmelden</Button>
                            <span style={{marginLeft:"10px"}}>
                                Für die Generierung von PDFs ist eine Anmeldung erforderlich.
                            </span>
                        </span>
                    )}
                    {user.isLoggedIn() && !user.getUser().school.licence.active && (
                        <span style={{marginLeft:"10px"}}>
                            <Button variant="primary" onClick={() => {user.openAbo(false)}} style={{marginRight:'10px'}}>Abo abschließen</Button>
                            <span style={{marginLeft:"10px"}}>
                                Für die Generierung von PDFs ist eine aktive Lizenzierung erforderlich.
                            </span>
                        </span>
                    )}
                  </Card.Text>
                  <Card.Text>
                    <canvas ref={canvasRef} width={width} height={height} />
                  </Card.Text>
              </Card.Body>
          </Card>
        </Col>
      </Row> 
  </div>
  );
};

export default Mandala;