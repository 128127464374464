import { Card, Nav } from 'react-bootstrap';
import PayPal from '../PayPal/PayPal';
import { useState } from 'react';
import useConfig from '../../services/utils/config';

const Abo = ({variant, onSelectAbo}) => {
    const config = useConfig();

    const [emClass, setEMClass] = useState('hovercard');
    const [eyClass, setEYClass] = useState('hovercard');
    const [syClass, setSYClass] = useState('hovercard');

    const [pid, setPid] = useState(null);

    const handleSetPid = (t, pid) => {
        if(t == 0){
            setEMClass("hovercard hovercard-selected");
            setEYClass("hovercard");
            setSYClass("hovercard");
        }else if(t == 1){
            setEMClass("hovercard");
            setEYClass("hovercard hovercard-selected");
            setSYClass("hovercard");
        }else if(t == 2){
            setEMClass("hovercard");
            setEYClass("hovercard");
            setSYClass("hovercard hovercard-selected");
        }

        setPid(pid);
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>Abo gefällig?</Card.Title>
                <Card.Subtitle>Du kannst dich auch später für ein Abo entscheiden, ohne Abo können aber keine PDFs generiert werden.</Card.Subtitle>
                <Card.Text>
                    <div style={{display:'flex', gap: '20px', marginBottom: '20px'}}>
                        {variant == 0 && (
                            <>
                                <Card className={emClass} style={{width:'50%'}} onClick={() => {handleSetPid(0, config.em)}}>
                                    <Card.Body>
                                        <Card.Text style={{textAlign:'center'}}>
                                            4,99 € pro Monat
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card className={eyClass} style={{width:'50%'}}  onClick={() => {handleSetPid(1, config.ey)}}>
                                    <Card.Body>
                                        <Card.Text style={{textAlign:'center'}}>
                                            55,00 € pro Jahr
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </>
                        )}
                        {variant == 1 && (
                            <>
                                <Card className={syClass} style={{width:'100%'}}  onClick={() => {handleSetPid(2, config.sy)}}>
                                    <Card.Body>
                                        <Card.Text style={{textAlign:'center'}}>
                                            Schulzugang 499 € pro Jahr, 5 Accounts inkl.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </>
                        )}
                    </div>
                    {pid != null && (
                        <PayPal pid={pid} onSuccess={() => {onSelectAbo()}} />
                    )}
                    <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                        <Nav.Link onClick={() => {onSelectAbo()}}>Überspringen</Nav.Link>
                    </div>
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default Abo;